import React from "react"
import { graphql, Link } from "gatsby"
import { useTranslation } from "react-i18next"

const Products = ({ data }) => {
  // const { variants, groups, lines, areas, products } = data
  // const { i18n, t } = useTranslation()

  // const navParentId = 128105773

  // const prodNav = {
  //   name: "Main Navigation",
  //   alternates: [],
  //   content: {
  //     component: "navigation",
  //     navigation: [
  //       {
  //         link: {
  //           id: "",
  //           url: "",
  //           linktype: "url",
  //           fieldtype: "multilink",
  //           cached_url: "",
  //         },
  //         title: process.env.GATSBY_PRODUCT_AREAS_BASE,
  //         component: "nav_item",
  //         sub_navigation: [
  //           {
  //             link: {
  //               id: "",
  //               url: `/${process.env.GATSBY_GLOBAL_PATH_PREFIX}${process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER}${i18n.language}/${process.env.PRODUCT_AREAS_BASE_PATH}`,
  //               linktype: "url",
  //               fieldtype: "multilink",
  //               cached_url: `/${process.env.GATSBY_GLOBAL_PATH_PREFIX}${process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER}${i18n.language}/${process.env.PRODUCT_AREAS_BASE_PATH}`,
  //             },
  //             title: t("generic.products.product_overview"),
  //             component: "nav_item",
  //             sub_navigation: [],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   slug: "mainNavigation",
  //   full_slug: `global/navigations/${i18n.language}/mainNavigation`,
  //   is_startpage: false,
  //   parent_id: navParentId,
  // }

  // const prodFooter = {
  //   name: "Footer",
  //   alternates: [],
  //   content: {
  //     component: "navigation",
  //     navigation: [
  //       {
  //         link: {
  //           id: "",
  //           url: `/${process.env.GATSBY_GLOBAL_PATH_PREFIX}${process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER}${i18n.language}/${process.env.PRODUCT_AREAS_BASE_PATH}`,
  //           linktype: "url",
  //           fieldtype: "multilink",
  //           cached_url: `/${process.env.GATSBY_GLOBAL_PATH_PREFIX}${process.env.GATSBY_GLOBAL_PATH_PREFIX_DELIMITER}${i18n.language}/${process.env.PRODUCT_AREAS_BASE_PATH}`,
  //         },
  //         title: process.env.GATSBY_PRODUCT_AREAS_BASE,
  //         component: "nav_item",
  //         sub_navigation: [],
  //       },
  //     ],
  //   },
  //   slug: "footer",
  //   full_slug: `global/navigations/${i18n.language}/footer`,
  //   is_startpage: false,
  //   parent_id: navParentId,
  // }

  // areas.edges.forEach((area) => {
  //   let subNavArea = {
  //     link: {
  //       id: "",
  //       url: "",
  //       linktype: "url",
  //       fieldtype: "multilink",
  //       cached_url: "",
  //     },
  //     title: area.node.name,
  //     component: "nav_item",
  //     sub_navigation: [
  //       {
  //         link: {
  //           id: "",
  //           url: area.node.fullSlug,
  //           linktype: "url",
  //           fieldtype: "multilink",
  //           cached_url: area.node.fullSlug,
  //         },
  //         title: t("generic.products.overview"),
  //         component: "sub_nav_link",
  //       },
  //     ],
  //   }

  //   prodFooter.content.navigation[0].sub_navigation.push({
  //     link: {
  //       id: "",
  //       url: area.node.fullSlug,
  //       linktype: "url",
  //       fieldtype: "multilink",
  //       cached_url: area.node.fullSlug,
  //     },
  //     title: area.node.name,
  //     component: "sub_nav_link",
  //   })

  //   lines.edges
  //     .filter((l) => l.node.areaId === area.node.areaId)
  //     .forEach((line) => {
  //       subNavArea.sub_navigation.push({
  //         link: {
  //           id: "",
  //           url: line.node.fullSlug,
  //           linktype: "url",
  //           fieldtype: "multilink",
  //           cached_url: line.node.fullSlug,
  //         },
  //         title: line.node.name,
  //         component: "sub_nav_link",
  //       })
  //     })
  //   prodNav.content.navigation[0].sub_navigation.push(subNavArea)
  // })

  // return (
  //   <>
  //     <div className="mx-4 my-4">
  //       <h2 className="text-2xl font-bold">Produkt Bereiche:</h2>
  //       <ul className="mt-2">
  //         {areas.edges.map((area, index) => {
  //           return (
  //             <li className="py-5" key={index}>
  //               <Link
  //                 className="flex hover:text-primary"
  //                 to={area.node.fullSlug}
  //               >
  //                 <p className="block">
  //                   <span className="w-20">{area.node.ID}</span>
  //                   <span className="w-20">{area.node.areaId}</span>
  //                   <span className="ml-6">{area.node.name}</span>
  //                 </p>
  //               </Link>
  //               <ul className="block ml-10">
  //                 {lines.edges
  //                   .filter((l) => l.node.areaId === area.node.areaId)
  //                   .map((line, lindex) => {
  //                     return (
  //                       <li key={lindex}>
  //                         <Link
  //                           className="flex hover:text-primary"
  //                           to={line.node.fullSlug}
  //                         >
  //                           <span className="w-20">
  //                             {line.node.externalKey}
  //                           </span>
  //                           <span className="w-20">{line.node.areaId}</span>
  //                           <span className="ml-6">{line.node.name}</span>
  //                         </Link>
  //                       </li>
  //                     )
  //                   })}
  //               </ul>
  //             </li>
  //           )
  //         })}
  //       </ul>

  //       <code class="block whitespace-pre overflow-x-scroll">
  //         {JSON.stringify(prodFooter, null, "  ")}
  //       </code>

  //       <code class="block whitespace-pre overflow-x-scroll">
  //         {JSON.stringify(prodNav, null, "  ")}
  //       </code>
  //     </div>
  //     <div className="mx-4 my-4">
  //       <h2 className="text-2xl font-bold">Produkt Gruppen:</h2>
  //       <ul className="mt-2">
  //         {groups.edges.map((group, index) => {
  //           return (
  //             <li key={index}>
  //               <Link
  //                 className="flex hover:text-primary"
  //                 to={group.node.fullSlug}
  //               >
  //                 <span className="w-20">{group.node.ID}</span>
  //                 <span className="w-20">{group.node.externalKey}</span>
  //                 <span className="ml-6">{group.node.name}</span>
  //               </Link>
  //             </li>
  //           )
  //         })}
  //       </ul>
  //     </div>

  //     <div className="mx-4 my-4">
  //       <h2 className="text-2xl font-bold">Produkte:</h2>
  //       <ul className="mt-2">
  //         {products.edges.map((products, index) => {
  //           return (
  //             <li key={index}>
  //               <Link
  //                 className="flex hover:text-primary"
  //                 to={products.node.fullSlug}
  //               >
  //                 <span className="w-20">{products.node.ID}</span>
  //                 <span className="w-20">{products.node.externalKey}</span>
  //                 <span className="ml-6">{products.node.name}</span>
  //               </Link>
  //             </li>
  //           )
  //         })}
  //       </ul>
  //     </div>

  //     <div className="mx-4 my-4">
  //       <h2 className="text-2xl font-bold">Varianten:</h2>
  //       <ul className="mt-2">
  //         {variants.edges.map((variant, index) => {
  //           return (
  //             <li key={index}>
  //               <Link
  //                 className="flex hover:text-primary"
  //                 to={variant.node.fullSlug}
  //               >
  //                 <span className="w-20">{variant.node.ID}</span>
  //                 <span className="w-20">{variant.node.externalKey}</span>
  //                 <span className="ml-6">{variant.node.name}</span>
  //               </Link>
  //             </li>
  //           )
  //         })}
  //       </ul>
  //     </div>
  //   </>
  // )
  return <div>product listings</div>
}

// export const data = graphql`
//   {
//     variants: allContentServVariant {
//       edges {
//         node {
//           name
//           externalKey
//           ID
//           fullSlug
//         }
//       }
//     }
//     products: allContentServProduct {
//       edges {
//         node {
//           name
//           externalKey
//           ID
//           fullSlug
//         }
//       }
//     }
//     groups: allContentServProductGroup {
//       edges {
//         node {
//           name
//           ID
//           externalKey
//           fullSlug
//         }
//       }
//     }
//     lines: allContentServProductLine {
//       edges {
//         node {
//           name
//           ID
//           areaId
//           externalKey
//           fullSlug
//         }
//       }
//     }
//     areas: allContentServProductArea {
//       edges {
//         node {
//           name
//           ID
//           areaId
//           externalKey
//           fullSlug
//         }
//       }
//     }
//   }
// `

export default Products
